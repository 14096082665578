/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useWishlistStore } from '~/stores/wishlist';
import { computed, useContext } from '@nuxtjs/composition-api';
import { useApi, useUiNotification, useUiState } from '~/composables';
import { GeminiEntityPrefixes } from '~/types/enums';
import { wishlistCookieName, UseWishlist, WishlistApiResult } from '~/types/wishlist';
import type { NuxtCookies } from 'cookie-universal-nuxt';
import { CREATE_WISHLIST, LOAD_WISHLIST, WISHLIST_ITEMS_COUNT, ADD_ITEM_TO_WISHLIST, ITEMS_IN_WISHLIST, REMOVE_ITEM_FROM_WISHLIST } from './queries';
import { handleError, notifySuccess } from './helpers';

const { WishlistItem: WishlistItemPrefix } = GeminiEntityPrefixes;

export const useWishlist = (): UseWishlist => {
  const {
    app,
    app: {
      $cookies: { set: setCookie, get: getCookie },
    },
  }: {
    app: {
      $cookies: Partial<NuxtCookies>;
      i18n: any;
    };
  } = useContext();
  const { mutate, query } = useApi();
  const { send: sendNotification } = useUiNotification();
  const { toggleWishlistSidebar: toggleWishlistSidebarUiState, isWishlistSidebarOpen } = useUiState();

  const wishlistStore = useWishlistStore();
  const { setWishlist, setWishlistItemsCount, setItemsInWishlist, removeItemsFromWishlist, setWishlistLoading } = wishlistStore;
  const wishlist = computed(() => wishlistStore.wishlist);
  const wishlistItemsCount = computed(() => wishlistStore.wishlistItemsCount);
  const itemsInWishlist = computed(() => wishlistStore.itemsInWishlist);
  const wishlistLoading = computed(() => wishlistStore.wishlistLoading);

  /**
   * Load wishlist data.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const loadWishlist = async (uid?: string) => {
    try {
      setWishlistLoading(true);
      const wishlistCookie: string = getCookie(wishlistCookieName);
      const uidToBeLoaded: string = uid || wishlistCookie;
      if (!uidToBeLoaded) {
        return;
      }
      const { data }: WishlistApiResult = await query(LOAD_WISHLIST, {
        wishlistUid: uidToBeLoaded,
        pageSize: 100,
        nextPageToken: null,
      });
      const loadedWishlist = data?.wishlist;
      if (loadedWishlist) {
        setWishlist(loadedWishlist);
        loadedWishlist.items.items.forEach((i) => {
          const wishlistItemUid = i.uid;
          const productId = i.item_grn;
          setItemsInWishlist({ [productId]: wishlistItemUid });
        });
      }
    } catch (error) {
      handleError(error, 'loadWishlist', sendNotification);
    } finally {
      setWishlistLoading(false);
    }
  };

  /**
   * Toggle the visibility of the wishlist sidebar.
   */
  const toggleWishlistSidebar = async () => {
    toggleWishlistSidebarUiState();
    if (isWishlistSidebarOpen.value) {
      await loadWishlist();
    }
  };

  /**
   * Create a new wishlist.
   * @param {boolean} loadAfterCreation - Whether to load the wishlist after creation.
   */
  const createWishlist = async () => {
    try {
      setWishlistLoading(true);
      const { data }: WishlistApiResult = await mutate(CREATE_WISHLIST);
      const newWishlistUid = data?.createWishlist?.uid;
      if (newWishlistUid) {
        setCookie(wishlistCookieName, newWishlistUid, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          sameSite: 'lax',
        });
        setWishlistItemsCount(0);
        setWishlistLoading(false);
        return newWishlistUid;
      }
    } catch (error) {
      handleError(error, 'createWishlist', sendNotification);
    } finally {
      setWishlistLoading(false);
    }
    return null;
  };

  /**
   * Load the count of items in the wishlist.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const loadWishlistItemsCount = async (uid?: string) => {
    try {
      setWishlistLoading(true);
      const wishlistCookie: string = getCookie(wishlistCookieName);
      const uidToBeLoaded: string = uid || wishlistCookie;
      if (!uidToBeLoaded) {
        setWishlistLoading(false);
        return;
      }
      const { data }: WishlistApiResult = await query(WISHLIST_ITEMS_COUNT, {
        wishlistUid: uidToBeLoaded,
      });
      const count = data?.wishlist?.items_count;
      if (count) setWishlistItemsCount(count);
    } catch (error) {
      handleError(error, 'loadWishlistItemsCount', sendNotification);
    }
    setWishlistLoading(false);
  };

  /**
   * Load items in the wishlist.
   * @param {string[]} items - The items to load.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const loadItemsInWishlist = async (items: string[], uid?: string) => {
    try {
      const wishlistCookie: string = getCookie(wishlistCookieName);
      const uidToBeLoaded: string = uid || wishlistCookie;
      if (!items || items.length === 0 || !uidToBeLoaded) {
        return;
      }
      setWishlistLoading(true);
      const { data }: WishlistApiResult = await query(ITEMS_IN_WISHLIST, {
        wishlistUid: uidToBeLoaded,
        items,
      });
      if (data.itemsInWishlist?.length > 0) {
        data.itemsInWishlist.forEach((i) => {
          const wishlistItemUid = i.wishlist_ids[0].item_uid;
          const productId = i.product_id;
          setItemsInWishlist({ [productId]: wishlistItemUid });
        });
      }
    } catch (error) {
      handleError(error, 'loadItemsInWishlist', sendNotification);
    } finally {
      setWishlistLoading(false);
    }
  };

  /**
   * Add an item to the wishlist.
   * @param {string} sku - The SKU of the item.
   * @param {number} quantity - The quantity of the item.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const addItemToWishlist = async (sku: string, quantity = 1, uid?: string) => {
    try {
      if (!sku) {
        throw new Error(app.i18n.t('No SKU provided'));
      }
      const wishlistCookie: string = getCookie(wishlistCookieName);
      let wishlistUid: string = uid || wishlistCookie;
      if (!wishlistUid) {
        wishlistUid = await createWishlist();
      }
      const { data }: WishlistApiResult = await mutate(ADD_ITEM_TO_WISHLIST, {
        wishlistUid,
        item: {
          quantity,
          sku,
        },
      });
      const itemWasAdded = data?.addItemToWishlist?.uid;
      if (!itemWasAdded) {
        throw new Error(app.i18n.t('Could not add item {{sku}} to wishlist', { sku }));
      }
      const currentItemsCount = wishlistItemsCount.value || 0;
      setWishlistItemsCount(Number.parseInt(`${currentItemsCount}`, 10) + 1);
      await toggleWishlistSidebar();
      notifySuccess(app.i18n.t('Product has been added to wishlist'), sendNotification);
    } catch (error) {
      handleError(error, 'addItemToWishlist', sendNotification);
    } finally {
      setWishlistLoading(false);
    }
  };

  /**
   * Remove an item from the wishlist.
   * @param {string} sku - The SKU of the item.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const removeItemFromWishlist = async (sku: string, uid?: string) => {
    try {
      if (!sku) {
        throw new Error(app.i18n.t('No SKU provided'));
      }
      setWishlistLoading(true);
      const wishlistCookie: string = getCookie(wishlistCookieName);
      const wishlistUid: string = uid || wishlistCookie;
      const wishlistItemUid = itemsInWishlist?.value?.[sku]?.replace(WishlistItemPrefix, '')?.replace(`${wishlistUid}/`, '');
      if (!wishlistItemUid) {
        setWishlistLoading(false);
        throw new Error(app.i18n.t('No wishlist item UID found for SKU {{sku}}', { sku }));
      }
      const { data }: WishlistApiResult = await mutate(REMOVE_ITEM_FROM_WISHLIST, {
        wishlistUid,
        wishlistItemUid,
        pageSize: 100,
        nextPageToken: null,
      });
      const wishlistWithItemRemoved = data?.removeItemFromWishlist;
      if (!wishlistWithItemRemoved) {
        setWishlistLoading(false);
        throw new Error(app.i18n.t('Could not remove item {{sku}} from wishlist', { sku }));
      }
      const currentItemsCount = wishlistItemsCount.value || 1;
      setWishlistItemsCount(currentItemsCount - 1);
      removeItemsFromWishlist([sku]);
      setWishlist(wishlistWithItemRemoved);
      notifySuccess(app.i18n.t('Product has been removed from wishlist'), sendNotification);
    } catch (error) {
      handleError(error, 'removeItemFromWishlist', sendNotification);
    } finally {
      setWishlistLoading(false);
    }
  };

  /**
   * Toggle an item in the wishlist.
   * @param {string} sku - The SKU of the item.
   * @param {string} uid - The unique identifier of the wishlist.
   */
  const toggleWishlistItem = async (sku: string, uid?: string) => {
    const itemIsInWishlist = itemsInWishlist?.value?.[sku];
    await (itemIsInWishlist ? removeItemFromWishlist(sku, uid) : addItemToWishlist(sku, 1, uid));
  };

  const resetState = async () => {
    // remove cookie gemini-wishlist
    setCookie(wishlistCookieName, null, {} as any);
    setWishlist(null);
    setWishlistItemsCount(null);
    setItemsInWishlist(null);
    await loadWishlist();

    setWishlistLoading(false);
  };

  return {
    wishlist,
    createWishlist,
    loadWishlist,
    loadWishlistItemsCount,
    wishlistItemsCount,
    addItemToWishlist,
    loadItemsInWishlist,
    itemsInWishlist,
    removeItemFromWishlist,
    toggleWishlistItem,
    wishlistLoading,
    toggleWishlistSidebar,
    resetState,
  };
};

export default useWishlist;
