/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Category } from '@gemini-vsf/api-client';
import { AgnosticCategoryTree, AgnosticFacet } from '@vue-storefront/core';
import { useContext, useRouter } from '@nuxtjs/composition-api';
import { categoryGetters } from '@gemini-vsf/composables';

const reduceFilters = (query, availableFacets) => (prev, curr) => {
  const makeArray = Array.isArray(query[curr]) || availableFacets.includes(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const useUiHelpers = () => {
  const router = useRouter();
  const {
    $vsf: {
      $gemini: {
        config: {
          // @ts-ignore
          facets: { available: availableFacets },
        },
      },
    },
    route: {
      value: { query },
    },
  } = useContext();

  const getFiltersDataFromUrl = (onlyFilters) =>
    Object.keys(query)
      .filter((filter) => (onlyFilters ? availableFacets.includes(filter) : !availableFacets.includes(filter)))
      .reduce(reduceFilters(query, availableFacets), {});

  const getFacetsFromURL = () => ({
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 24,
    page: Number.parseInt(query.page as string, 10) || 1,
    sort: (query.sort as string) || '',
    term: query.term as string,
  });

  const changeSearchTerm = (term: string) => term;

  const getSearchTermFromUrl = () => ({
    page: Number.parseInt(query.page as string, 10) || 1,
    sort: query.sort || '',
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 10,
    term: query.term,
  });

  const getCatLink = (category: Category): string => categoryGetters.getSlug(category);

  const getAgnosticCatLink = (category: AgnosticCategoryTree): string => categoryGetters.getSlug(category);

  const changeSorting = async (sort: string) => {
    await router.push({ query: { ...query, sort } });
  };

  const changeFilters = async (filters: any) => {
    const filtersDataFromUrl = getFiltersDataFromUrl(false);
    if (Object.keys(filtersDataFromUrl).includes('page')) {
      delete filtersDataFromUrl.page;
    }
    await router.push({
      query: {
        ...filtersDataFromUrl,
        ...filters,
      },
    });
  };

  const changeItemsPerPage = async (itemsPerPage: number) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        itemsPerPage,
      },
    });
  };

  const setTermForUrl = async (term: string) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined,
      },
    });
  };

  const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';

  const isFacetCheckbox = (): boolean => false;

  return {
    getFacetsFromURL,
    getCatLink,
    getAgnosticCatLink,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    setTermForUrl,
    isFacetColor,
    isFacetCheckbox,
    getSearchTermFromUrl,
    changeSearchTerm,
  };
};

export default useUiHelpers;
