/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useProduct, useCategorySearch, categoryGetters } from '@gemini-vsf/composables';
import { sharedRef } from '@vue-storefront/core';
import { useRoute } from '@nuxtjs/composition-api';

export const useSearchPage = (keyword: string) => {
  const {
    value: {
      query: { page },
    },
  } = useRoute();
  const { products: productsComposable, search: productsSearchComposable } = useProduct(`Search:${keyword}`);
  const { result: categoriesSearchResult, search: categoriesSearch } = useCategorySearch(`Search:Categories:${keyword}`);
  const products = sharedRef(null, `useSearchPage-products-${keyword}`);
  const loadingProducts = sharedRef(false, `useSearchPage-loadingProducts-${keyword}`);
  const emptyResult = sharedRef(false, `useSearchPage-emptyResult-${keyword}`);
  const aggregations = sharedRef(null, `useSearchPage-aggregations-${keyword}`);
  const loadingCategories = sharedRef(false, `useSearchPage-loadingCategories-${keyword}`);
  const categories = sharedRef(null, `useSearchPage-categories-${keyword}`);

  const search = async ({ pageSize }: { pageSize?: number }) => {
    if (!keyword) return;
    loadingProducts.value = true;
    const pageNumber = page ? Number.parseInt(page as string, 10) : 1;
    try {
      await productsSearchComposable({
        queryType: 'LIST',
        search: keyword,
        page: pageNumber,
        pageSize: pageSize || 24,
        customQuery: {
          products: 'searchPage',
        },
      });
      products.value = productsComposable.value.items;
      emptyResult.value = Array.isArray(products.value) && products.value.length === 0;
      aggregations.value = productsComposable.value.aggregations;
    } catch (error) {
      console.error(error);
    } finally {
      loadingProducts.value = false;
    }
  };

  const searchCategories = async (): Promise<void> => {
    if (!products.value || products.value.length === 0) return;
    try {
      loadingCategories.value = true;
      const catsIds = aggregations?.value?.find((aggr) => aggr?.attribute_code === 'cats_ids');
      const populatedCatsIds = catsIds?.options.reduce((acc, option) => {
        const key = option.value.split('::').pop();
        acc[key] = option.count;
        return acc;
      }, {});
      await categoriesSearch({
        filters: { category_uid: { in: Object.keys(populatedCatsIds) } },
      });
      categories.value = categoriesSearchResult.value
        .map((element) => ({
          ...categoryGetters.getCategoryTree(element),
          count: populatedCatsIds?.[element?.uid?.split('::')?.pop() || 0] || 0,
        }))
        .filter((c) => c.label && c.slug)
        .sort((a, b) => b.count - a.count);
    } catch (error) {
      console.warn('searchCategories ~ error:', error);
    } finally {
      loadingCategories.value = false;
    }
  };

  const load = async ({ pageSize }: { pageSize?: number }) => {
    await search({ pageSize: 24 });
    await searchCategories();
  };

  return {
    products,
    loadingProducts,
    loadingCategories,
    categories,
    load,
    emptyResult,
    productsComposable,
  };
};

export default useSearchPage;
